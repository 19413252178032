<template>
<div>
  <section class="image-gallery">
    <div class="image" v-bind:key="photo._id">
      <img :src="photo.path" />
      <div class="photoInfo">
        <p class="photoTitle">{{photo.title}}</p>
        <p class="photoName">{{photo.user.firstName}} {{photo.user.lastName}}</p>
      </div>
      <p class="photoDate">{{formatDate(photo.created)}}</p>
    </div>
  </section>
  <div class="commentForm" v-if="user">
    <h2>Add a Comment</h2>
    <div class="form">
      <textarea v-model="comment"></textarea>
      <br />
      <button @click="addComment">Comment</button>
    </div>
  </div>
  <div class="comments">
    <h2>Comments</h2>
    <div class="comment" v-for="comment in comments" v-bind:key="comment._id">
      <p class="commentText">{{comment.comment}}</p>
      <h4 class="commentAuthor"> -- {{comment.user.firstName}} {{comment.user.lastName}}</h4>
      <p class="commentDate">{{formatDate(comment.created)}}</p>
      <br />
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

export default {
  name: 'photoView',
  data() {
    return {
      photo: null,
      comments: [],
      newComment: null,
      error: '',
      comment: '',
    }
  },
  async created() {
    this.getPhoto();
    this.getComments();
    try {
      let response = await axios.get('/api/users');
      this.$root.$data.user = response.data.user;
    } catch (error) {
      this.$root.$data.user = null;
    }
  },
  computed: {
    user() {
      return this.$root.$data.user;
    }
  },
  methods: {
    async getPhoto() {
      let photoId = this.$route.params.id;
      try {
        let response = await axios.get("/api/photos/" + photoId);
        this.photo = response.data;
      } catch (error) {
        this.error = error.response.data.message;
      }
    },
    formatDate(date) {
      if (moment(date).diff(Date.now(), 'days') < 15)
        return moment(date).fromNow();
      else
        return moment(date).format('d MMMM YYYY');
    },
    async addComment() {
      let photoId = this.$route.params.id;
      try {
        let response = await axios.post('/api/comments/' + photoId, {
          comment: this.comment,
        });
        this.newComment = response.data.comment;
        //console.log("Added Comment: ", this.newComment);
        this.getComments();
      } catch (error) {
        this.error = error.response.data.message;
      }
    },
    async getComments() {
      let photoId = this.$route.params.id;
      try {
        let response = await axios.get('/api/comments/' + photoId);
        this.comments = response.data;
        //console.log("Retrieved Comments: ", this.comments);
        //console.log(response.data);
        //console.log(response.data[0].comment);
      } catch (error) {
        this.comments = null;
        this.error = error.response.data.message;
      }
    }
  }
}
</script>

<style scoped>
.photoInfo {
  display: flex;
  justify-content: space-between;
  font-size: 0.8em;
}
.photoInfo p {
  margin: 0px;
}
.photoDate {
  font-size: 0.7em;
  font-weight: normal;
}
p {
  margin: 0px;
}
.comments h2 {
  font-size: 1.5em;
}
.comment {
  border-top: 1px solid;
  padding: 15px 0px 0px 10px;
}
.commentDate {
  font-style: italic;
  font-size: 15px;
}
/* Masonry */
*,
*:before,
*:after {
  box-sizing: inherit;
}
.image-gallery {
  column-gap: 1em;
}
.image {
  margin: 0 0 1.5em;
  display: inline-block;
  width: 100%;
}
.image img {
  width: 100%;
}
/* Masonry on large screens */
@media only screen and (min-width: 1024px) {
  .image-gallery {
    column-count: 1;
  }
}
/* Masonry on medium-sized screens */
@media only screen and (max-width: 1023px) and (min-width: 768px) {
  .image-gallery {
    column-count: 1;
  }
}
/* Masonry on small screens */
@media only screen and (max-width: 767px) and (min-width: 540px) {
  .image-gallery {
    column-count: 1;
  }
}
</style>
